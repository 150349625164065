import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

// Featured Slider
const feature_slider = new Swiper('.feature-slider-container', {
    direction: 'horizontal',
    autoHeight: false,
    a11y: true,
    observer: true,
    observeParents: true,
    loop: false,
    parallax: true,
    watchOverflow: true,
    fadeEffect: {
      crossFade: true
    },  
    speed: 500,
    effect: 'fade',
    autoplay: {
      delay: 8000,
    },
    pagination: {
      el: '.feature-slider-controls',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">0' + (index + 1) + '</span>';
      },
    },
});
// Content Slider
const content_slider = new Swiper('.swiper-container', {
    direction: 'horizontal',
    autoHeight: false,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 50
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 25
      },
      1440: {
        slidesPerView: 3,
        spaceBetween: 25
      }
    },
    autoplay: {
      delay: 3000,
    },
    centeredSlides: false,
    loop: jQuery(".swiper-container .swiper-slide").length != 1,
    watchOverflow: true, 
    navigation: {
      prevEl: '.swiper-previous',
      nextEl: '.swiper-next',
    },
});
// Logos Slider
var logo_swiper = new Swiper('#client_logos', {
    spaceBetween: 0,
    centeredSlides: true,
    speed: 6000,
    autoplay: {
      delay: 1,
    },
    loop: true,
    slidesPerView:'auto',
    allowTouchMove: false,
    disableOnInteraction: true
});

// Testimonial Slider
const testimonial_content_slider = new Swiper('.testimonial-slider-container', {
    direction: 'horizontal',
    a11y: true,
    autoHeight: true,
    loop: true,
    autoplay: {
      delay: 4000,
    },
    fadeEffect: {
      crossFade: true
    },  
    effect: 'fade',
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
});