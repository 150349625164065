import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
import {ScrollToPlugin} from "gsap/ScrollToPlugin"
import {MotionPathPlugin} from "gsap/MotionPathPlugin"
import {EasePack} from "gsap/EasePack"

gsap.config({
  nullTargetWarn: false,
  trialWarn: false,
});

gsap.set('.null', {opacity: 1})
gsap.registerPlugin(ScrollTrigger);
const split_left = gsap.utils.toArray('.animate_left');
gsap.set(split_left, {autoAlpha: 0, x: 250});
split_left.forEach((box_left, i) => {
  const anim = gsap.to(box_left, {duration: 0.5, autoAlpha: 1, x: 0, paused: true});
ScrollTrigger.create({
    trigger: box_left,
    start: "top 75%",
    once: false,
    onEnter: self => {
      self.progress === 1 ? anim.progress(1) : anim.play()
    }
  });
});

const split_right = gsap.utils.toArray('.animate_right');
gsap.set(split_right, {autoAlpha: 0, x: -250});
split_right.forEach((box_right, i) => {
  const anim = gsap.to(box_right, {duration: 0.5, autoAlpha: 1, x: 0, paused: true});
ScrollTrigger.create({
    trigger: box_right,
    start: "top 75%",
    once: false,
    onEnter: self => {
      self.progress === 1 ? anim.progress(1) : anim.play()
    }
  });
});

// Parallax sections
// gsap.registerPlugin(ScrollTrigger);

// let getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight);
// gsap.utils.toArray(".content-block").forEach((section, i) => {
//   section.bg = section.querySelector(".parallax"); 
//   gsap.fromTo(section.bg, {
//     backgroundPosition: () => i ? `50% ${-window.innerHeight * getRatio(section)}px` : "50% 0"
//   }, {
//     backgroundPosition: () => `50% ${window.innerHeight * (1 - getRatio(section))}px`,
//     ease: "none",
//     scrollTrigger: {
//       trigger: section,
//       start: () => i ? "top bottom" : "top top", 
//       end: "bottom top",
//       scrub: 0.75,
//       invalidateOnRefresh: false,
//     }
//   });
// });

// Social Media module
if (jQuery(window).width() > 750) {
	const phone = gsap.timeline({
	scrollTrigger: {
		trigger: ".pinned",
		pin: true,
		scrub: true,
		start: "top 20%",
		end: "bottom 20%",
		anticipatePin: 1,
		toggleActions: "play pause play reset"
	}
	});
	phone.from(".phone_content", {
	opacity: 1,
	duration: 1
	})
}
// Rotating icons
gsap.to(".trigger", {
  duration: 2, 
  rotation: 360,
  scrollTrigger: {
    trigger: ".trigger",
    start: "top center",
    end: "top 0",
    scrub: true
  }
});

// Mio Footer Logo
const footer_logo = gsap.utils.toArray('.footer_logo');
gsap.set(footer_logo, {autoAlpha: 0, y: 50});

footer_logo.forEach((pop_up, i) => {
  const anim = gsap.to(pop_up, {duration: 1, autoAlpha: 1, y: 0, paused: true});
  
ScrollTrigger.create({
    trigger: pop_up,
    start: "top 80%",
    end: "bottom 10%",
    once: false,
    onEnter: self => {
      self.progress === 1 ? anim.progress(1) : anim.play()
    }
  });
});

// Mio Mountains
gsap.utils.toArray('.mio_mountains').forEach(section => {
  const elems = section.querySelectorAll('.mt');
  gsap.set(elems, { y: 150, opacity: 1 });
  
  ScrollTrigger.create({
    trigger: ".mio_mountains",
    start: 'top 200%',
    //markers: true,
    toggleActions: "play reverse",
    onEnter: () => gsap.to(elems, {
      y: 0,
      opacity: 1,
      duration: 1,
      stagger: 0.3,
      delay: 0.3,
      ease: 'power3.out',
      overwrite: 'auto'
    }),
    onLeaveBack: () => gsap.to(elems, {
      y: 100,
      opacity: 1,
      duration: 1,
      stagger: 0.2,
      delay: 0.1,
      ease: 'power3.out',
      overwrite: 'auto'
    })
  });
})

// Blue Butterfly
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(MotionPathPlugin)

const rx = window.innerWidth < 1000 ? window.innerWidth / 1000 : 1
const ry = window.innerHeight < 700 ? window.innerHeight / 700 : 1

const path = [
		// 1
		{ x: 0, y: 250 },
		{ x: -100, y: 450 },
		{ x: -200, y: 650 },
		// 2
		{ x: -50, y: 950 },
		{ x: -300, y: 1150 },
		{ x: 0, y: 1350 },
		// 3
		{ x: -300, y: 1650 },
		{ x: 0, y: 1850 },
		{ x: -100, y: 2050 },
		// 4
		{ x: -400, y: 2350 },
		{ x: -200, y: 2650 },
		{ x: -50, y: 2950 },
		// 5
		{ x: -200, y: 3350 },
		{ x: -100, y: 3650 },
		{ x: -400, y: 3900 }
		]

const scaledPath = path.map(({ x, y }) => {
	return {
		x: x * rx,
		y: y * ry
	}
})

// const sections = document.querySelectorAll('div.panel')
const butterfly = document.querySelector('.blue_butterfly')
const tl = gsap.timeline({
	scrollTrigger: {
		scrub: 1.5,
	},
})
tl.to(butterfly, {
	motionPath: {
		path: scaledPath,
		align: 'self',
		alignOrigin: [0.5, 0.5],
		autoRotate: true
	},
	duration: 10,
	immediateRender: true,
	// ease: 'power4'
})
tl.to(butterfly, {
	rotateX: 0
}, 1)
tl.to(butterfly, {
	rotateX: 180
}, 2)
tl.to(butterfly, {
	z: -50,
	duration: 2,
}, 2.5)
tl.to(butterfly, {
	rotateX: 180
}, 4)
tl.to(butterfly, {
	rotateX: 0
}, 5.5)
tl.to(butterfly, {
	z: -50,
	duration: 2,
}, 5)
tl.to(butterfly, {
	rotate: 0,
	duration: 1,
}, '-=1')


// Orange butterfly
const rx_o = window.innerWidth < 2000 ? window.innerWidth / 2000 : 1
const ry_o = window.innerHeight < 700 ? window.innerHeight / 700 : 1

const path_o = [
			// 1
			{ x: 200, y: 250 },
			{ x: 100, y: 450 },
			{ x: 0, y: 650 },
			// 2
			{ x: 400, y: 950 },
			{ x: 0, y: 1150 },
			{ x: 200, y: 1350 },
			// 3
			{ x: 100, y: 1650 },
			{ x: 200, y: 1850 },
			{ x: -200, y: 2050 },
			// 4
			{ x: 0, y: 2350 },
			{ x: 400, y: 2650 },
			{ x: -200, y: 2950 },
			// 5
			{ x: 200, y: 3450 },
			{ x: 0, y: 3650 },
			{ x: -100, y: 3850 }
		]

const scaledPath_o = path_o.map(({ x, y }) => {
	return {
		x: x * rx_o,
		y: y * ry_o
	}
})

const butterfly_o = document.querySelector('.orange_butterfly')
const tl_o = gsap.timeline({
	scrollTrigger: {
		scrub: 1.5,
	},
})
tl_o.to(butterfly_o, {
	motionPath: {
		path: scaledPath_o,
		align: 'self',
		alignOrigin: [0.5, 0.5],
		autoRotate: true
	},
	duration: 10,
	immediateRender: true,
	// ease: 'power4'
})
tl_o.to(butterfly_o, {
	rotateX: 180
}, 1)
tl_o.to(butterfly_o, {
	rotateX: 0
}, 2.5)
tl_o.to(butterfly_o, {
	z: -50,
	duration: 2,
}, 2.5)
tl_o.to(butterfly_o, {
	rotateX: 180
}, 4)
tl_o.to(butterfly_o, {
	rotateX: 0
}, 5.5)
tl_o.to(butterfly_o, {
	z: -50,
	duration: 2,
}, 5)
tl_o.to(butterfly_o, {
	rotate: 0,
	duration: 1,
}, '-=1')


// const rotateButterfly = (self) => {
// 	if (self.direction === -1) {
// 		gsap.to(butterfly, { rotationY: 180, duration: 0.4 })
// 	} else {
// 		gsap.to(butterfly, { rotationY: 0, duration: 0.4 })
// 	}
// }
// sections.forEach((section, i) => {

// 	ScrollTrigger.create({
// 		trigger: section,
// 		start: "top top",
// 		onUpdate: (self) => rotateButterfly(self)
// 	})
// })


// red section
// gsap.registerPlugin(ScrollTrigger);
// gsap.to(".butterfly1", {
//   y: -130,
//   duration: 2,
//   ease: "elastic",
//   scrollTrigger: {
//     trigger: ".butterfly1",
//     start: "left center",
//     toggleActions: "play none none reset",
//     id: "1",
//   }
// });

// gsap.to(".butterfly2", {
// 	y: -190,
// 	duration: 2,
// 	ease: "elastic",
// 	scrollTrigger: {
// 	  trigger: ".butterfly2",
// 	  start: "left center",
// 	  toggleActions: "play none none reset",
// 	  id: "2",
// 	}
//   });
